import {Component, ElementRef, Input, ViewChild} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder} from "@angular/forms";
import {Order, OrderDetail, OrderPartsFinish} from "./orders.models";
import {Product} from "../products/products.model";
import {DomHandler, SelectItem} from "primeng/api";
import {ProductsService} from "../products/products.service";

@Component({
    selector: 'orderDetails',
    templateUrl: './details.component.html',
    styleUrls: ['./orders.component.css']
})
export class DetailsComponent {

    public orderDetails: OrderDetail[] = [];
    public selectedOrderDetail: OrderDetail = new OrderDetail();
    private _finishId: string;
    private _finish: OrderPartsFinish;
    private _detailsLoading: boolean = false;
    private _columns: number = 8;
    private _canEdit: boolean;
    private _canDelete: boolean;
    private _canAdd: boolean;
    private _orderDetailsDirtyRows: boolean[] = [];
    private _products: SelectItem[] = [];
    private _productsFiltered: SelectItem[] = [];

    @Input() get finish(): OrderPartsFinish {
        return this._finish;
    }

    set finish(finish: OrderPartsFinish) {
        if (this._orderDetailsDirtyRows.length > 0) {
            this.saveDirtyRows();
        }
        this._finish = finish;
        this._finishId = finish.id;
        this.loadDetails();
    }

    @Input() labourCost: number;

    @Input() set saveDetails(saveDetails) {
        if (saveDetails) {
            this.saveDirtyRows();
        }
    }

    @Input() set reloadDetails(load: boolean) {
        if (load) {
            console.info('Load FinishId: ' + this._finishId);
            this.loadDetails();
        }
    }

    constructor(private _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder, private _productsService: ProductsService) {
        this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        this._canAdd = (this._auth.modules['orders'].authLevel >= 30);
        this._canDelete = (this._auth.modules['orders'].authLevel >= 40);
        if (this._canDelete) {
            this._columns = this._columns + 1;
        }
        this._products = this._productsService.getProductsDD();
        //this.allProducts();
    }

    selectInputContent(event) {
        event.target.select();
    }

    selectDetail(event) {
        if (event.data.sortOrderNumber !== this.selectedOrderDetail.sortOrderNumber)
            this.saveDirtyRows();
        this.selectedOrderDetail = event.data;
    }

    loadDetails() {
        this._detailsLoading = true;
        this._apiService.setActionUrl('orders/orderdetails');
        this._apiService.setFilter({field: 'orderPartFinishId', value: this._finishId, operator: 'eq'});
        this._apiService.setSort({field: 'sortOrderNumber', direction: 'ASC'});
        this._apiService.getStore().subscribe(response => {
            this._detailsLoading = false;
            if (response.success) {
                this.orderDetails = response.data.records;
                this.addRow();
            } else {
                this._auth.addError(response.errorMsg);
            }
            this._products = this._productsService.getProductsDD();
        });
    }

    gotoNextCell(event, detail: OrderDetail, ri: number) {
        let vc = document.getElementById('product_' + detail.sortOrderNumber);
        let nextCell = document.getElementById('materialCost_' + detail.sortOrderNumber);
        if (nextCell) {
            DomHandler.invokeElementMethod(vc, 'blur');
            DomHandler.invokeElementMethod(nextCell, 'focus');
            if (typeof event.preventDefault === "function") {
                event.preventDefault();
            }
        }
    }

    orderDetailMove(orderDetail: OrderDetail, newRow: number) {
        let newSortOrderNumber = orderDetail.sortOrderNumber;
        orderDetail.sortOrderNumber = this.orderDetails[newRow].sortOrderNumber;
        this.orderDetails[newRow].sortOrderNumber = newSortOrderNumber;
        this.orderDetailsSave(this.orderDetails[newRow]);
        this.orderDetailsSave(orderDetail, true);
    }

    orderDetailsSave(orderDetail: OrderDetail, reload: boolean = false) {
        if (orderDetail.productId.length == 0) {
            return;
        }
        if (orderDetail.orderPartFinishId.length == 0) {
            return;
        }
        if (orderDetail.materialCost == null || orderDetail.materialFactor == null ||
            orderDetail.materialLoss == null || orderDetail.labourCost == null ||
            orderDetail.labourFactor == null) {
            return;
        }
        this._apiService.setActionUrl('orders/orderdetails');
        if (orderDetail.id.length === 0) {
            this._apiService.createRecord(JSON.stringify(orderDetail)).subscribe(
                response => {
                    if (response.success) {
                        orderDetail.id = response.data.records[0].id;
                        if (reload) {
                            this.loadDetails();
                        }
                        this.addRow();
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                }
            )
        } else {
            this._apiService.updateRecord(JSON.stringify(orderDetail)).subscribe(
                response => {
                    if (response.success) {
                        if (reload) {
                            this.loadDetails();
                        }
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                }
            )
        }
    }

    orderDetailDelete(orderDetail: OrderDetail, row: number) {
        this._apiService.setActionUrl('orders/orderdetails');
        if (orderDetail.id == '') {
            return;
        }
        this._apiService.deleteRecord(orderDetail.id).subscribe(response => {
            if (response.success) {
                this.loadDetails();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });

    }

    saveDirtyRows() {
        if (this._orderDetailsDirtyRows.length > 0) {
            this._orderDetailsDirtyRows.forEach(function(value, index) {
                // check if row is valid
                let details: OrderDetail = this.orderDetails[index];
                if (details.productId.length == 0) {
                    return;
                }
                if (details.orderPartFinishId.length == 0) {
                    return;
                }
                this.orderDetailsSave(details);
            }, this)
            this._orderDetailsDirtyRows = [];
        }
    }

    setDirtyFlag(row: number) {
        this._orderDetailsDirtyRows[row] = true;
        if (this.orderDetails.length == (row + 1)) {
            this.addRow();
        }
    }

    addRow() {
        if (this._canAdd && this._finishId.length > 0) {
            let newOrderDetails = new OrderDetail();
            newOrderDetails.orderPartFinishId = this._finishId;
            newOrderDetails.quantity = this._finish.size;
            let lastNumber = 0;
            if (this.orderDetails.length > 0) {
                lastNumber = this.orderDetails[this.orderDetails.length - 1].sortOrderNumber;
            }
            newOrderDetails.sortOrderNumber = lastNumber + 1;
            newOrderDetails.labourFactor = 1;
            newOrderDetails.materialFactor = 1;
            newOrderDetails.product = new Product();
            this.orderDetails.push(newOrderDetails);
        }
    }
    changeProduct(event: SelectItem, row: number, detail: OrderDetail) {
        this.gotoNextCell(event, detail, row);
        if (detail.productId !== event.value) {
            detail.productId = event.value;
            this._apiService.setActionUrl('products/products');
            this._apiService.setFilter({field: 'id', value: event.value, operator: 'eq'});
            this._apiService.getStore().subscribe(response => {
                if (response.success) {
                    detail.product = response.data.records[0];
                    detail.materialCost = detail.product.materialCost;
                    detail.materialLoss = detail.product.materialLoss;
                    let labCost: number = 0;
                    if (detail.product.piecesPerHour > 0)
                        labCost = (1 / detail.product.piecesPerHour) * this.labourCost;

                    detail.labourCost = Math.round((labCost + 0.00001) * 100) / 100;
                    this.orderDetailsSave(detail);
                } else {
                    this._auth.addError(response.errorMsg);
                }
            });
        }
    }

    calcMaterial(quantity: number, loss: number) {
        let factor: number = (1 + (loss / 100));
        let material: number = quantity * factor;
        return Math.round(material * 1000) / 1000;
    }

    filterProducts(event) {
        this._productsFiltered = this._st.filterAutoComplete(event, this._products);
    }

    allProducts() {
        this._productsFiltered = this._products;
    }

}
