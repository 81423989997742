import {NgModule} from "@angular/core";
import {AclForm} from "./acl.service";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {DropdownModule, ButtonModule, DialogModule} from "primeng/primeng";
import {TableModule} from "primeng/table";

@NgModule({
    declarations: [
        AclForm
    ],
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        ButtonModule,
        DropdownModule,
        DialogModule,
        TableModule
    ],
    exports: [
        AclForm
    ]
})
export class AclModule {}