import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder} from "@angular/forms";
import {OrderPart, OrderPartsFinish} from "./orders.models";
import {Finish} from "../masterdata/masterdata.models";
import {ConfirmationService, MenuItem, SelectItem} from "primeng/api";
import {ShortcutInput} from "ng-keyboard-shortcuts";

@Component({
    selector: 'orderFinishes',
    templateUrl: './finishes.component.html',
    styleUrls: ['./orders.component.css'],
    providers: [ConfirmationService]
})
export class FinishesComponent implements OnInit, AfterViewInit {

    public orderPartsFinishes: OrderPartsFinish[] = [];
    public selectedFinish: OrderPartsFinish = new OrderPartsFinish();

    private _orderPartId: string;
    private _finishesLoading: boolean = false;
    private _orderPart: OrderPart;
    private _columns: number = 3;
    private _canEdit: boolean;
    private _canDelete: boolean;
    private _canAdd: boolean;
    private _visible: boolean = false;
    private _saveDetails: boolean = false;
    private _firstLoad: boolean = false;
    private _loadDetails: boolean = false;
    private _finishMenu: MenuItem[] = [];
    private _kbShortCuts: ShortcutInput[] = [];

    private _finishes: SelectItem[] = [];
    private _finishesFiltered: SelectItem[] = [];

    @Input() get part(): OrderPart {
        return this._orderPart;
    }

    @Input() labourCost: number;

    @Input() set orderReadOnly(readOnly: boolean) {
        if (readOnly) {
            this._canEdit = false;
        } else {
            this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        }
    }

    set part(part: OrderPart) {
        this._orderPart = part;
        this._orderPartId = part.id;
        this.loadFinishes();
    }

    @Input() get visible(): boolean {
        return this._visible;
    }

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    set visible(visible: boolean) {
        this._visible = visible;
    }

    constructor(private _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder) {
        this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        this._canAdd = (this._auth.modules['orders'].authLevel >= 30);
        this._canDelete = (this._auth.modules['orders'].authLevel >= 40);
        if (this._canDelete) {
            this._columns = this._columns + 1;
        }
        this._finishMenu = [
            {label: 'Kopiëer afwerkingen', icon: 'fa fa-copy', command: (event) => this.copyFinish()},
            {label: 'Plak afwerkingen', icon: 'fa fa-paste', command: (event) => this.pasteFinish()}
        ]

    }

    ngOnInit(): void {
        this.loadFinishesDD();
        this._saveDetails = false;
        this._firstLoad = true;
    }

    ngAfterViewInit(): void {
        this._kbShortCuts.push({
            key: 'ctrl + c',
            preventDefault: true,
            description: 'Kopiëer afwerkingen',
            label: "Commando's",
            command: event => this.copyFinish()
        }, {
            key: 'ctrl + v',
            preventDefault: true,
            description: 'Plak aftwerkingen',
            label: "Commando's",
            command: event => this.pasteFinish()
        });
    }

    selectInputContent(event) {
        event.target.select();
    }

    loadFinishes() {
        this._finishesLoading = true;
        this._apiService.setActionUrl('orders/orderpartfinishes');
        this._apiService.setFilter({field: 'orderPartId', value: this._orderPartId, operator: 'eq'});
        this._apiService.setSort({field: 'sortOrderNumber', direction: 'ASC'});
        this._apiService.getStore().subscribe(response => {
            this._finishesLoading = false;
            if (response.success) {
                this.orderPartsFinishes = response.data.records;
                this.addRow();
                if (this._firstLoad) {
                    this.selectedFinish = this.orderPartsFinishes[0];
                    this._firstLoad = false;
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadFinishesDD() {
        this._apiService.setActionUrl('masterdata/finishes');
        this._apiService.setSort({field: 'code', direction: 'ASC'});
        this._apiService.getDropDown(JSON.stringify(['code', 'description']), 'id', 'N').subscribe(response => {
            if (response.success) {
                this._finishes = response.data.records;
                //this.allFinishes();
            }
        });
    }

    selectFinish(event) {
        this.selectedFinish = event.data;
    }

    changeFinish(event, row: number, finish: OrderPartsFinish) {
        finish.finishId = event.value;
        this._apiService.setActionUrl('masterdata/finishes');
        this._apiService.setFilter({field: 'id', value: event.value, operator: 'eq'});
        this._apiService.getStore().subscribe(response => {
            if (response.success) {
                finish.finish = response.data.records[0];
                finish.description = finish.finish.description.replace("??", finish.size.toString());
                this.orderPartFinishSave(finish);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    orderPartFinishSave(partsFinish: OrderPartsFinish, reload: boolean = false) {
        this._loadDetails = false;
        if (partsFinish.finishId.length == 0) {
            return;
        }
        this._apiService.setActionUrl('orders/orderpartfinishes');
        if (partsFinish.id.length === 0) {
            this._apiService.createRecord(JSON.stringify(partsFinish)).subscribe(
                response => {
                    if (response.success) {
                        partsFinish.id = response.data.records[0].id;
                        this.selectedFinish = { ... partsFinish};
                        this._loadDetails = true;
                        if (reload) {
                            this.loadFinishes();
                        }
                        this.addRow();
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                }
            )
        } else {
            this._apiService.updateRecord(JSON.stringify(partsFinish)).subscribe(
                response => {
                    if (response.success) {
                        if (reload) {
                            this.loadFinishes();
                        }
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                }
            )
        }
    }

    orderPartFinishDelete(partsFinish: OrderPartsFinish, row: number) {
        this._apiService.setActionUrl('orders/orderpartfinishes');
        if (partsFinish.id == '') {
            return;
        }
        this._apiService.deleteRecord(partsFinish.id).subscribe(response => {
            if (response.success) {
                this.loadFinishes();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    orderPartFinishMove(partsFinish: OrderPartsFinish, newRow: number) {
        let newSortOrderNumber = partsFinish.sortOrderNumber;
        partsFinish.sortOrderNumber = this.orderPartsFinishes[newRow].sortOrderNumber;
        this.orderPartsFinishes[newRow].sortOrderNumber = newSortOrderNumber;
        this.orderPartFinishSave(this.orderPartsFinishes[newRow]);
        this.orderPartFinishSave(partsFinish, true);
    }

    hideDialog() {
        this._visible = false;
        this.visibleChange.emit(this._visible);
    }

    addRow() {
        if (this._canAdd) {
            let newFinish = new OrderPartsFinish();
            newFinish.orderPartId = this._orderPartId;
            newFinish.size = this._orderPart.size;
            let lastNumber = 0;
            if (this.orderPartsFinishes.length > 0) {
                lastNumber = this.orderPartsFinishes[this.orderPartsFinishes.length - 1].sortOrderNumber;
            }
            newFinish.sortOrderNumber = lastNumber + 1;
            newFinish.finish = new Finish();
            this.orderPartsFinishes.push(newFinish);
        }
    }

    filterFinishes(event) {
        this._finishesFiltered = this._st.filterAutoComplete(event, this._finishes);
    }

    allFinishes() {
        this._finishesFiltered = this._finishes;
    }

    copyFinish() {
        this._st.copyFinish(this.selectedFinish.id);
    }

    pasteFinish() {
        let orgFinishId = this._st.mergeFinish();
        this._apiService.setActionUrl('orders/orderpartfinishes');
        this._apiService.specialPostAction(JSON.stringify({orgFinishId: orgFinishId, newFinishId: this.selectedFinish.id}), 'mergeFinish')
            .subscribe(response => {
                this.selectedFinish = new OrderPartsFinish();
                if (response.success) {
                    this.selectedFinish = response.data.records[0];
                }
            });
    }
}
