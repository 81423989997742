import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder} from "@angular/forms";
import {Order, OrderPart} from "./orders.models";
import {MenuItem} from "primeng/api";
import {ShortcutInput} from "ng-keyboard-shortcuts";

@Component({
    selector: 'orderParts',
    templateUrl: './parts.component.html',
    styleUrls: ['./orders.component.css']
})
export class PartsComponent implements AfterViewInit {

    public orderParts: OrderPart[] = [];
    public selectedOrderPart: OrderPart = new OrderPart();
    public kbShortCuts: ShortcutInput[] = [];

    private _orderId: string;
    private _order: Order;
    private _reloadParts: boolean = false;
    private _loadingParts: boolean = false;
    private _tmpOrderParts: OrderPart[] = [];
    private _orderPartsDirtyRows: boolean[] = [];
    private _columns: number = 3;
    private _canEdit: boolean;
    private _canDelete: boolean;
    private _canAdd: boolean;
    private _showFinishes: boolean = false;

    public partItems: MenuItem[];

    @Input() get order(): Order {
        return this._order;
    }

    set order(order: Order) {
        this._order = order;
        if (this._order.readOnly) {
            this._canEdit = false;
        } else {
            this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        }
        if (order) {
            this._orderId = order.id;
            this._loadingParts = true;
            this.loadOrderParts();
        }
    }

    @Output() showFinishesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() get showFinishes(): boolean {
        return this._showFinishes
    }

    set showFinishes(show: boolean) {
        this._showFinishes = show;
        if (!show) {
            this.orderPartChange.emit(new OrderPart());
        }
    }

    @Input() get orderPart(): OrderPart {
        return this.selectedOrderPart;
    }

    set orderPart(part: OrderPart) {
        this.selectedOrderPart = part;
    }

    @Output() orderPartChange: EventEmitter<OrderPart> = new EventEmitter<OrderPart>();

    @Input() get reloadParts(): boolean {
        return this._reloadParts;
    }

    set reloadParts(reload: boolean) {
        this._reloadParts = reload;
        this.loadOrderParts();
    }

    @Output() reloadPartsChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder) {
        this._canEdit = (this._auth.modules['orders'].authLevel >= 20);
        this._canAdd = (this._auth.modules['orders'].authLevel >= 30);
        this._canDelete = (this._auth.modules['orders'].authLevel >= 40);
        if (this._canDelete) {
            this._columns = this._columns + 1;
        }

        this.partItems = [
            {label: 'Finishes', icon: 'fa fa-flag', command: (event) => this.openFinishes()}
        ]
    }


    ngAfterViewInit(): void {
        this.kbShortCuts.push(
            {
                key: 'ctrl + s',
                preventDefault: true,
                description: "Opslaan",
                label: "Commando's",
                command: event => this.saveDirtyRows()
            }, {
                key: 'ctrl + f',
                preventDefault: true,
                description: "Open afwerkingen",
                label: "Commando's",
                command: event => this.openFinishes()
            }, {
                key: 'up',
                preventDefault: true,
                description: 'Omhoog',
                label: 'Navigatie',
                throttleTime: 100,
                command: event => this.rowUp()
            }, {
                key: 'down',
                preventDefault: true,
                description: 'Omlaag',
                label: 'Navigatie',
                throttleTime: 100,
                command: event => this.rowDown()
            }
        );
    }

    rowUp() {
        let row = this.orderParts.indexOf(this.selectedOrderPart);
        if (row > 0) {
            this.selectedOrderPart = this.orderParts[row - 1];
        }
    }

    rowDown() {
        let row = this.orderParts.indexOf(this.selectedOrderPart);
        if (row < this.orderParts.length - 1) {
            this.selectedOrderPart = this.orderParts[row + 1];
        }
    }

    openFinishes() {
        this._showFinishes = true;
        this.showFinishesChange.emit(true);
        this.orderPartChange.emit(this.selectedOrderPart);
    }

    loadOrderParts() {
        this._apiService.setActionUrl('orders/orderparts');
        this._apiService.setSort({field: 'CHAR_LENGTH(code)', direction: 'ASC'});
        this._apiService.setSort({field: 'code', direction: 'ASC'});
        this._apiService.setFilter({field: 'orderId', value: this._orderId, operator: 'eq'});
        this._apiService.getStore().subscribe(response => {
            this._loadingParts = false;
            this.reloadPartsChange.emit(false);
            if (response.success) {
                this.orderParts = response.data.records;
                this.addRow();
                this.orderParts.forEach(function (part, index) {
                    if (part.length > 0) {
                        part._lengthFilled = true;
                    }
                    if (part.size > 0) {
                        part._sizeFilled = true;
                    }
                    this.orderParts[index] = part;
                }, this);
                this.selectedOrderPart = this.orderParts[0];
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    orderPartEditInit(part: OrderPart) {
        this._tmpOrderParts[part.id] = {...part};
    }

    orderPartEditSave(part: OrderPart, index: number = -1) {
        this._apiService.setActionUrl('orders/orderparts');
        if (part.id.length === 0) {
            this._apiService.createRecord(JSON.stringify(part)).subscribe(
                response => {
                    if (response.success) {
                        part.id = response.data.records[0].id;
                        if (index > -1) {
                            this.orderParts[index].id = response.data.records[0].id;
                        }
                        this.addRow();
                        //this.selectedOrderPart = new OrderPart();
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                }
            )
        } else {
            this._apiService.updateRecord(JSON.stringify(part)).subscribe(
                response => {
                    if (response.success) {
                        //this.selectedOrderPart = new OrderPart();
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                }
            )
        }
    }

    orderPartDelete(part: OrderPart, row: number) {
        this._apiService.setActionUrl('orders/orderparts');
        if (part.id == '') {
            return;
        }
        this._apiService.deleteRecord(part.id).subscribe(response => {
            if (response.success) {
                this.loadOrderParts();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    setDirtyFlag(row: number) {
        this._orderPartsDirtyRows[row] = true;
        if (this.orderParts.length == (row + 1)) {
            this.addRow();
        }
    }

    sizeChanged(part: OrderPart, row: number) {
        if (part.length > 0) {
            part._lengthFilled = true;
        } else {
            part._lengthFilled = false;
        }
        if (part.size > 0) {
            part._sizeFilled = true;
        } else {
            part._sizeFilled = false;
        }
        this.orderPartEditSave(part, row);
    }

    addRow() {
        if (this._canAdd) {
            let newOrderPart = new OrderPart();
            if (this.orderParts.length > 0) {
                let lastCode = this.orderParts[this.orderParts.length - 1].code;
                if (lastCode.substr(lastCode.length - 1, 1) == 'Z') {
                    if (lastCode.length == 1) {
                        newOrderPart.code = 'AA';
                    } else {
                        newOrderPart.code = String.fromCharCode(lastCode.charCodeAt(0) + 1) + 'A';
                    }
                } else {
                    if (lastCode.length == 1) {
                        newOrderPart.code = String.fromCharCode(lastCode.charCodeAt(lastCode.length - 1) + 1);
                    } else {
                        newOrderPart.code = lastCode.substr(0,1) + String.fromCharCode(lastCode.charCodeAt(lastCode.length - 1) + 1);
                    }
                }
            }
            newOrderPart.orderId = this._orderId;
            this.orderParts.push(newOrderPart);
        }
    }

    selectOrderPart(event) {
        if (event.data.code !== this.selectedOrderPart.code)
            this.saveDirtyRows();
        this.selectedOrderPart = event.data;
    }

    saveDirtyRows() {
        if (this._orderPartsDirtyRows.length > 0) {
            this._orderPartsDirtyRows.forEach(function (value, index) {
                this.orderPartEditSave(this.orderParts[index], index);
            }, this);
            this._orderPartsDirtyRows = [];
        }
    }

    getRowStyle(orderPart: OrderPart, row: number) {
        if (orderPart.id == this.selectedOrderPart.id) {
            return {'background-color': 'green', 'color': 'white'}
        } else {
            if (row % 2) {
                return {'background-color': 'white', 'color': 'black'}
            } else {
                return {'background-color': 'lightgrey', 'color': 'black'}
            }
        }
    }
}
