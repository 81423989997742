import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, SelectItem} from "primeng/api";
import {Order, OrderCustomer} from "./orders.models";

@Component({
    selector: 'newCompany',
    templateUrl: './company.component.html',
    styleUrls: ['./orders.component.css']
})
export class CompanyComponent implements OnInit {

    private _customerDropDown: SelectItem[] = [];
    private _contactDropDown: SelectItem[] = [];
    private _showNewCompany: boolean = false;
    private _companyForm: FormGroup;
    private _canEdit: boolean;

    constructor(private _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder, private _confirmationService: ConfirmationService) {
        this._canEdit = true;
    }

    @Input() get showNewCompany(): boolean {
        return this._showNewCompany;
    }

    @Input() orderId: string;

    @Input() sortOrder: string;

    @Input() set customer(customer: OrderCustomer) {
        if (customer.id !== '') {
            this._companyForm.setValue(customer as any);
            this.loadContacts(customer.customer.id);
        } else {
            if (this._companyForm) {
                this._companyForm.reset();
                this._companyForm.controls.id.patchValue('');
            }
        }
    };

    set showNewCompany(show: boolean) {
        this._showNewCompany = show;
    }

    @Output() showNewCompanyChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onHide: EventEmitter<any> = new EventEmitter();

    hideDialog() {
        this.onHide.emit();
        this.showNewCompanyChange.emit(false);
    }
    ngOnInit(): void {
        this.loadCustomers();
        this._companyForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'contactId': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'contact': new FormControl({value: '', disabled: true}),
            'customerId': new FormControl({value: '', disabled: !this._canEdit}, Validators.required),
            'customer': new FormControl({value: '', disabled: true}),
            'orderId': new FormControl({value: '', disabled: true}),
            'sortOrder': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
        });
    }

    saveCustomer() {
        let record: OrderCustomer = this._companyForm.getRawValue();
        record.orderId = this.orderId;
        this._apiService.setActionUrl('orders/orderCustomers');
        if (record.id == '') {
            record.sortOrder = String.fromCharCode(this.sortOrder.charCodeAt(0) + 1);
            this._apiService.createRecord(JSON.stringify(record)).subscribe(response => {
                this._showNewCompany = false;
            });
        } else {
            this._apiService.updateRecord(JSON.stringify(record)).subscribe(response => {
                this._showNewCompany = false;
            });

        }
    }

    loadCustomers() {
        this._apiService.setActionUrl('base/go');
        let filter = {type: 'customer'};
        this._apiService.specialPostAction(JSON.stringify(filter), 'loadrelations').subscribe(response => {
            if (response.success) {
                this._customerDropDown.push({value: '', label: this._transService.instant('base.useSelectValue')});
                response.data.records.forEach(function(customer) {
                    this._customerDropDown.push({value: customer.id, label: customer.name});
                }, this)
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    preLoadContacts(event) {
        let companyId = event.value;
        this.loadContacts(companyId);
    }

    loadContacts(companyId) {
        this._contactDropDown = [];
        this._contactDropDown.push({value: '', label: this._transService.instant('base.loading')});
        this._apiService.setActionUrl('base/go');
        let filter = {type: 'customerContact', companyId: companyId};
        this._apiService.specialPostAction(JSON.stringify(filter), 'loadrelations').subscribe(response => {
            if (response.success) {
                this._contactDropDown = [];
                this._contactDropDown.push({value: '', label: this._transService.instant('base.useSelectValue')});
                response.data.records.forEach(function(contact) {
                    this._contactDropDown.push({value: contact.id, label: contact.name});
                }, this);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

}
