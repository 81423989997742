
import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, TitleCasePipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    ButtonModule, DropdownModule, InputTextModule, TabViewModule, DialogModule, AutoCompleteModule, CalendarModule,
    TreeTableModule, SharedModule as SharedPrimeNg, CheckboxModule, SpinnerModule, PasswordModule, FieldsetModule, ProgressBarModule,
    MessagesModule, FileUploadModule, MultiSelectModule, ConfirmDialogModule, RadioButtonModule, ContextMenuModule, InputSwitchModule, InputTextareaModule, CardModule
} from 'primeng/primeng';
import {HttpClientModule} from "@angular/common/http";
import {ApiService} from "./api.service";
import {ToolsModule} from "./support.tools";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AclService} from "./acl.service";
import {AuthService} from "./auth.service";
import {GroupsService} from "./groups/groups.service";
import {UsersService} from "./users/users.service";
import {AclModule} from "./acl.module";
import {TableModule} from "primeng/table";
import {ConfirmationService} from "primeng/api";
import {ListboxModule} from "primeng/listbox";
import {KeyboardShortcutsModule} from "ng-keyboard-shortcuts";
import {ProductsService} from "./products/products.service";

@NgModule({
    declarations: [

    ],
    imports: [

    ],
    exports: [
        AclModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ButtonModule,
        TableModule,
        InputTextModule,
        TabViewModule,
        DropdownModule,
        CalendarModule,
        DialogModule,
        AutoCompleteModule,
        TreeTableModule,
        SharedPrimeNg,
        CheckboxModule,
        SpinnerModule,
        PasswordModule,
        FieldsetModule,
        ProgressBarModule,
        MessagesModule,
        FileUploadModule,
        MultiSelectModule,
        BrowserAnimationsModule,
        ToolsModule,
        ConfirmDialogModule,
        RadioButtonModule,
        ListboxModule,
        ContextMenuModule,
        InputSwitchModule,
        InputTextareaModule,
        KeyboardShortcutsModule
    ],
    providers: [
        ApiService,
        AclService,
        AuthService,
        GroupsService,
        UsersService,
        ProductsService,
        ConfirmationService,
        TitleCasePipe,
        DatePipe,
        CurrencyPipe,
        DecimalPipe
    ]
})
export class SharedModule {}
