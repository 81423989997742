import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "../api.service";
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    private _login: loginModel = new loginModel();
    private _forgotMailAddress: string;
    private _loginForm: boolean = true;
    private _userNameForm: boolean = false;

    constructor(private translate: TranslateService, private _api: ApiService, private _authService: AuthService, private _router: Router) {
        this._authService.loginPending = true;
    }

    ngOnInit() {
    }

    login() {
        this._api.setActionUrl('base/authentication');
        this._api.specialPostAction(JSON.stringify(this._login), 'login').subscribe(
            response => {
                if (response.success) {
                    if (response.readTranslations) {
                        this.translate.use(response.userSettings.language);
                    }
                    this._authService.doLogin(response);
                    this._authService.loginPending = false;
                    this._router.navigate(['/']);
                } else {
                    console.error(response.errorMsg);
                    this._authService.addError('Foutieve gebruikersnaam of wachtwoord');
                }
            }, error => {
                this._authService.addError(error.error.text);
            }
        );
    }

    forgotPassword() {
        if (this._login.userName === '') {
            // set alert with warning
        }
    }

    forgotUserName() {
        this._loginForm = false;
        this._userNameForm = true;
    }
    userName() {
        this._api.setActionUrl('base/authentication');
        this._api.specialPostAction(JSON.stringify({mailAddress: this._forgotMailAddress}), 'forgotUserName').subscribe(
            response => {
                console.info(response.sendParams);
            }, error => {
                this._authService.addError(error.error.text);
            }
        );
        this._userNameForm = false;
        this._loginForm = true;
    }

}

export class loginModel {
    userName: string;
    password: string
}
