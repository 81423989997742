export class Finish {
    id: string;
    code: string;
    description: string;
}

export class ShippingMethod {
    id: string;
    shippingMethod: string;
}

export class Warranty {
    id: string;
    description: string;
}