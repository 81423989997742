import {User} from "../users/users.models";

export class Country {
    id: string;
    country: string;
}

export class NameModel {
    id: string;
    name: string;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}

export class Setting {
    id: string;
    key: string;
    value: any;
    type: string;
    module: string;
}
