/**
 * This file is part of the <<projectName>> project
 * This project is licensed under the license what can be found on https://www.dj-consultancy.nl/licenses/eolmodules.pdf
 *
 * Id: $id$
 * File: aclService
 * Author: Pieter van de Ven
 * Copyright: Pieter van de Ven / DJ Consultancy
 * Date:
 * License: <<fill in>>
 */

import {Component, Injectable, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";
import {UsersService} from "./users/users.service";
import {GroupsService} from "./groups/groups.service";
import {SupportTools} from "./support.tools";
import {User} from "./users/users.models";
import {Group} from "./groups/groups.models";

@Injectable()
export class AclService {

    public aclUsers: Acl[];
    public aclGroups: Acl[];
    public aclItemId: string;

    constructor(private _apiService: ApiService, private _authService: AuthService) {
    }

    public getAcl(aclItemId: string) {
        this._apiService.setActionUrl('base/acl');
        this.aclItemId = aclItemId;
        this._apiService.resetFilter();
        this._apiService.setFilter({field: 'aclItemId', operator: 'eq', value: aclItemId});
        this._apiService.setFilter({field: 'userId', operator: 'ne', value: ''});
        this._apiService.getStore()
            .subscribe((resp) => {
                if (resp.success) {
                    this.aclUsers = resp.data.records;
                } else {
                    this._authService.addError(resp.errorMsg);
                }
            }, error => {
                this._authService.addError(error.error.text);
            });
        this._apiService.resetFilter();
        this._apiService.setFilter({field: 'aclItemId', operator: 'eq', value: aclItemId});
        this._apiService.setFilter({field: 'groupId', operator: 'ne', value: ''});
        this._apiService.getStore()
            .subscribe((resp) => {
                if (resp.success) {
                    this.aclGroups = resp.data.records;
                } else {
                    this._authService.addError(resp.errorMsg);
                }
            }, error => {
                this._authService.addError(error.error.text);
            });
    }

}

export class Acl {
    id: string;
    aclItemId: string;
    user: User;
    userId: string;
    group: Group;
    groupId: string;
    aclLevel: number;
    aclLevelName: string;
}

@Component({
    selector: 'acl',
    providers: [UsersService, GroupsService],
    templateUrl: './acl.service.html'
})

export class AclForm implements OnInit {

    public aclUser: Acl = new Acl();
    public selectedUserAcl: Acl;
    public aclGroup: Acl = new Acl();
    public selectedGroupAcl: Acl;
    public newAclUser: boolean = false;
    public newAclGroup: boolean = false;
    public showAclUser: boolean = false;
    public showAclGroup: boolean = false;
    public showTable: boolean = true;
    public crudOptions: any = [];
    public minWidth: number = 500;

    constructor(public aclService: AclService, private translate: TranslateService, private _usersService: UsersService, private _apiService: ApiService,
                private _groupsService: GroupsService, private _authService: AuthService, private _st: SupportTools) {
    }

    ngOnInit() {
        this.translate.get(['base.view', 'base.create', 'base.edit', 'base.delete', 'base.manage']).subscribe((resp) => {
            this.crudOptions.push({label: resp['base.view'], value: 10});
            this.crudOptions.push({label: resp['base.create'], value: 20});
            this.crudOptions.push({label: resp['base.edit'], value: 30});
            this.crudOptions.push({label: resp['base.delete'], value: 50});
            this.crudOptions.push({label: resp['base.manage'], value: 100});
        });
        this._usersService.getDropDown();
        this._groupsService.getDropDown();
    }

    showDialogToAdd(aclType: string) {
        if (aclType === 'user') {
            this.newAclUser = true;
            this.showAclUser = true;
            //this.showTable = false;
            this.aclUser = new Acl();
            this.aclUser.aclLevel = 10;
        }
        if (aclType === 'group') {
            this.newAclGroup = true;
            this.showAclGroup = true;
            //this.showTable = false;
            this.aclGroup = new Acl();
            this.aclGroup.aclLevel = 10;
        }
    }

    showEditDialog(aclType: string) {
        if (aclType === 'user') {
            this.showAclUser = true;
        }
        if (aclType === 'group') {
            this.showAclGroup = true;
        }
    }

    onSelect(event, aclType: string) {
        if (aclType === 'user') {
            this.aclUser = this._st.cloneRecord(event.data, new Acl());
        }
        if (aclType === 'group') {
            this.aclGroup = this._st.cloneRecord(event.data, new Acl());
        }
    }

    save(aclType: string) {
        this._apiService.setActionUrl('base/acl');
        if (aclType === 'group') {
            this.aclGroup.userId = '';
            if (this.newAclGroup) {
                this.aclGroup.aclItemId = this.aclService.aclItemId;
                this._apiService.createRecord(JSON.stringify(this.aclGroup))
                    .subscribe((resp) => {
                        if (resp.success) {
                            this.aclService.getAcl(this.aclService.aclItemId);
                            this.newAclGroup = false;
                            this.cancel();
                        } else {
                            this._authService.addError(resp.errorMsg);
                        }
                    }, error => {
                        this._authService.addError(error.error.text);
                    });
            } else {
                this._apiService.updateRecord(JSON.stringify(this.aclGroup))
                    .subscribe((resp) => {
                        if (resp.success) {
                            this.aclService.getAcl(this.aclService.aclItemId);
                            this.cancel();
                        } else {
                            this._authService.addError(resp.errorMsg);
                        }
                    }, error => {
                        this._authService.addError(error.error.text);
                    });
            }

        }
        if (aclType === 'user') {
            this.aclUser.groupId = '';
            if (this.newAclUser) {
                this.aclUser.aclItemId = this.aclService.aclItemId;
                this._apiService.createRecord(JSON.stringify(this.aclUser))
                    .subscribe((resp) => {
                        if (resp.success) {
                            this.aclService.getAcl(this.aclService.aclItemId);
                            this.newAclUser = false;
                            this.cancel();
                        } else {
                            this._authService.addError(resp.errorMsg);
                        }
                    }, error => {
                        this._authService.addError(error.error.text);
                    });
            } else {
                this._apiService.updateRecord(JSON.stringify(this.aclUser))
                    .subscribe((resp) => {
                        if (resp.success) {
                            this.aclService.getAcl(this.aclService.aclItemId);
                            this.cancel();
                        } else {
                            this._authService.addError(resp.errorMsg);
                        }
                    }, error => {
                        this._authService.addError(error.error.text);
                    });
            }

        }
    }

    delete(aclType: string) {
        this._apiService.setActionUrl('base/acl');
        if (aclType === 'group') {
            this.aclService.aclGroups.splice(this.aclService.aclGroups.indexOf(this.selectedGroupAcl), 1);
            this.showAclGroup = false;
            this._apiService.deleteRecord(this.selectedGroupAcl.id)
                .subscribe((resp) => {
                    if (resp.success) {
                        this.aclService.getAcl(this.aclService.aclItemId);
                    } else {
                        this._authService.addError(resp.errorMsg);
                        this.aclService.getAcl(this.aclService.aclItemId);
                    }
                }, error => {
                    this._authService.addError(error.error.text);
                });
        }
        if (aclType === 'user') {
            this.aclService.aclUsers.splice(this.aclService.aclUsers.indexOf(this.selectedUserAcl), 1);
            this.showAclUser = false;
            this._apiService.deleteRecord(this.selectedUserAcl.id)
                .subscribe((resp) => {
                    if (resp.success) {
                        this.aclService.getAcl(this.aclService.aclItemId);
                    } else {
                        this._authService.addError(resp.errorMsg);
                        this.aclService.getAcl(this.aclService.aclItemId);
                    }
                }, error => {
                    this._authService.addError(error.error.text);
                });
        }
    }

    cancel() {
        this.showTable = true;
        this.showAclUser = false;
        this.showAclGroup = false;
    }

}
