import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {OrdersComponent} from "./orders.component";
import {PartsComponent} from "./parts.component";
import {FinishesComponent} from "./finishes.component";
import {DetailsComponent} from "./details.component";
import {CompanyComponent} from "./company.component";
import {ScrollPanelModule} from "primeng/primeng";

@NgModule({
    imports: [
        SharedModule,
        ScrollPanelModule
    ],
    declarations: [
        OrdersComponent,
        PartsComponent,
        FinishesComponent,
        DetailsComponent,
        CompanyComponent
    ]
})
export class OrdersModule {

}
