import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {Product, ProductType} from "./products.model";
import {MenuItem, SelectItem} from "primeng/api";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {ShortcutInput} from "ng-keyboard-shortcuts";
import {Table} from "primeng/table";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, AfterViewInit {

    public selectedItem: string;
    public selectedProductType: ProductType = new ProductType();
    public selectedProduct: Product = new Product();
    public products: Product[] = [];
    public productTypes: ProductType[] = [];
    public productsLoading: boolean = false;
    public typesLoading: boolean = false;
    public suppliers: any[] = [];

    private _showProducts: boolean = false;
    private _showProductDialog: boolean = false;
    private _showTypes: boolean = false;
    private _tmpProductTypes: ProductType[] = [];
    private _contextMenu: MenuItem[] = [];
    private _contextMenuProduct: MenuItem[] = [];
    private _showCancelYesNo: boolean = false;
    private _productForm: FormGroup;
    private _supplierDD: SelectItem[] = [];

    public kbShortCuts: ShortcutInput[] = [];

    constructor(private _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService, private _fb: FormBuilder) {

    }

    @ViewChild('typesTable',  {static: false}) tt: Table;

    ngOnInit(): void {
        this.loadTypes();
        this.loadSuppliers();
        this._showTypes = true;
        this._transService.get(['products.showProducts', 'base.edit', 'base.delete', 'base.details']).subscribe(response => {
            this._contextMenu.push({label: response['products.showProducts'], icon: 'fa fa-list', command: (event) => this.showProducts()});
            if (this._auth.modules['products'].authLevel >= 20) {
                //this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editRecord()});
                this._contextMenuProduct.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.startEditProduct(event)});
            }
            if (this._auth.modules['products'].authLevel >= 40) {
                this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteType()});
                this._contextMenuProduct.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteProduct(event)});
            }
        });

        this._productForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'oldDatabase': new FormControl({value: '', disabled: true}),
            'productTypeId': new FormControl({value: '', disabled: true}),
            'productType': new FormControl({value: '', disabled: true}),
            'supplierId': new FormControl(''),
            'supplier': new FormControl({value: '', disabled: true}),
            'name': new FormControl('', Validators.required),
            'description': new FormControl(''),
            'itemCode': new FormControl('', Validators.required),
            'materialCost': new FormControl('', Validators.required),
            'materialLoss': new FormControl('', Validators.required),
            'piecesPerHour': new FormControl('', Validators.required),
            'labourHours': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        })
    }

    ngAfterViewInit(): void {
        this.kbShortCuts.push(
            {
                key: 'ctrl + s',
                preventDefault: true,
                description: "Opslaan",
                label: "Commando's",
                command: event => this.save()
            }, {
                key: 'ctrl + e',
                preventDefault: true,
                description: "Bewerken",
                label: "Commando's",
                command: event => this.editRecord()
            }, {
                key: 'up',
                preventDefault: true,
                description: 'Omhoog',
                label: 'Navigatie',
                command: event => this.rowUp()
            }, {
                key: 'up',
                preventDefault: true,
                description: 'Omlaag',
                label: 'Navigatie',
                command: event => this.rowDown()
            }
        );
    }

    save() {
        if (this._showProducts) {
            this.saveProduct();
        } else {
            this.typeEditSave(this.selectedProductType);
        }
    }

    rowUp() {
        if (this._showProducts) {
            let curRow = this.products.indexOf(this.selectedProduct);
            if (curRow == 0) {
                return;
            }
            this.selectedProduct = this.products[curRow - 1];
        } else {
            let curRow = this.productTypes.indexOf(this.selectedProductType);
            if (curRow == 0) {
                return;
            }
            this.selectedProductType = this.productTypes[curRow -1];
        }
    }

    rowDown() {
        if (this._showProducts) {
            let curRow = this.products.indexOf(this.selectedProduct);
            if (curRow == this.products.length - 1) {
                return;
            }
            this.selectedProduct = this.products[curRow + 1];
        } else {
            let curRow = this.productTypes.indexOf(this.selectedProductType);
            if (curRow == this.productTypes.length - 1) {
                return;
            }
            this.selectedProductType = this.productTypes[curRow + 1];
        }
    }
    showProducts() {
        this.loadProducts();
        this._showProducts = true;
    }

    loadProducts() {
        this.productsLoading = true;
        this._apiService.setActionUrl('products/products');
        if (this.selectedProductType) {
            this._apiService.setFilter({field: 'productTypeId', value: this.selectedProductType.id, operator: 'eq'});
        }
        this._apiService.getStore().subscribe(response => {
            this.productsLoading = false;
            if (response.success) {
                this.products = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadSuppliers() {
        this._apiService.setActionUrl('base/go');
        let filter = {type: 'supplier'};
        this._apiService.specialPostAction(JSON.stringify(filter), 'loadrelations').subscribe(response => {
            if (response.success) {
                this.suppliers = response.data.records;
                this._supplierDD.push({value: '', label: this._transService.instant('base.useSelectValue')});
                this.suppliers.forEach(function(supplier) {
                    this._supplierDD.push({value: supplier.id, label: supplier.name});
                }, this)
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadTypes() {
        this.typesLoading = true;
        this._apiService.setActionUrl('products/types');
        this._apiService.getStore().subscribe(response => {
            this.typesLoading = false;
            if (response.success) {
                this.productTypes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    typeEditInit(type: ProductType) {
        this._tmpProductTypes[type.id] = { ... type };
    }

    typeEditSave(type: ProductType) {
        if (type.code.length > 0) {
            this._apiService.setActionUrl('products/types');
            if (type.id.length === 0) {
                this._apiService.createRecord(JSON.stringify(type)).subscribe(response => {
                    if (response.success) {
                        this.selectedProductType = new ProductType();
                    }
                });
            } else {
                this._apiService.updateRecord(JSON.stringify(type)).subscribe(response => {
                    if (response.success) {
                        this.selectedProductType = new ProductType();
                    }
                });
            }
        }
    }

    typeEditCancel(type: ProductType, row: number) {
        this.productTypes[row] = this._tmpProductTypes[type.id];
        this._tmpProductTypes[type.id] = null;
    }

    createType() {
        let type = new ProductType();
        type.id = '';
        this.productTypes.unshift(type);
    }

    deleteType() {
        this._apiService.setActionUrl('products/types');
        this._apiService.deleteRecord(this.selectedProductType.id).subscribe(response => {
            if (response.success) {
                this.loadTypes();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    cancelUnselectPT(event) {
        this.selectedProductType = event.data;
    }

    startEditProduct(event) {
        this.editProduct(this.selectedProduct);
    }

    editProduct(product: Product) {
        this.selectedProduct = { ... product};
        this._showProductDialog = true;
        this._showProducts = false;
        if (this.selectedProduct.id !== '') {
            this._productForm.setValue(this.selectedProduct as any)
        } else {
            this._productForm.reset();
            this._productForm.controls.id.patchValue('');
        }
    }

    deleteProduct(event) {
        this._apiService.setActionUrl('products/products');
        this._apiService.deleteRecord(this.selectedProduct.id).subscribe(response => {
            if (response.success) {
                this.loadProducts();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    cancelProductListDialog() {
        this._showProducts = false;
        this.tt.selection = null;
    }

    cancelProductDialog() {
        // check if product is dirty
        let dirty: boolean = false;
        if (dirty) {
            this._showCancelYesNo = true;
        } else {
            this.productEditCancel();
        }
    }

    preSaveProduct(product: Product) {
        this.selectedProduct = product;
        this.saveProduct();
    }

    preSaveProductForm() {
        this.selectedProduct = this._productForm.getRawValue();
        this.saveProduct();
    }

    saveProduct() {

        this._apiService.setActionUrl('products/products');
        if (this.selectedProduct.id == ''){
            this.selectedProduct.productTypeId = this.selectedProductType.id;
            this._apiService.createRecord(JSON.stringify(this.selectedProduct)).subscribe(response => {
                if (response.success) {
                    if (this._showProductDialog) {
                        this.cancelProductDialog();
                        this.selectedProduct = new Product();
                        this.loadProducts();
                    }
                } else {
                    this._auth.addError(response.errorMsg);
                }
            });
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedProduct)).subscribe(response => {
                if (response.success) {
                    if (this._showProductDialog) {
                        this.cancelProductDialog();
                        this.selectedProduct = new Product();
                    }
                } else {
                    this._auth.addError(response.errorMsg);
                }
            });
        }

    }

    productEditCancel() {
        this._showProductDialog = false;
        this._showProducts = true;
    }

    editRecord() {
        if (this._showProducts) {
            this.editProduct(this.selectedProduct);
            return;
        }
        if (this._showTypes) {
            this.typeEditInit(this.selectedProductType);
            this.tt.initRowEdit(this.selectedProductType);
            return;
        }
    }

    cancelUnselectProduct(event) {
        this.selectedProduct = event.data;
    }

    createProduct() {
        this.selectedProduct = new Product();
        this.editProduct(new Product());
    }

}
