/**
 * This file is part of the <<projectName>> project
 * This project is licensed under the license what can be found on https://www.dj-consultancy.nl/licenses/eolmodules.pdf
 *
 * Id: $id$
 * File: groups
 * Author: Pieter van de Ven
 * Copyright: Pieter van de Ven / DJ Consultancy
 * Date:
 * License: <<fill in>>
 */

export class Group {
    id: string;
    name: string;
    managerFullName: string;
    ownerId: string;
    aclItemId: string;
    createdAt: number;
    modifiedAt: number;
    softDeleted: number;

}

export class Member {
    groupId: string;
    groupDescription: string;
    userId: string;
    userFullName: string
}

export class GroupDropDown {
    label: string;
    value: string;
}

