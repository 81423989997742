import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {SelectItem} from "primeng/api";
import {AuthService} from "../auth.service";

@Injectable()
export class ProductsService {

    private _productsDD: SelectItem[] = [];
    private _loaded: boolean = false;
    private _loading: boolean = false;

    constructor(private _apiService: ApiService, private _auth: AuthService) {

    }

    public getProductsDD() {
        if (!this._loading && !this._loaded) {
            this._productsDD = [];
            this.loadProductsDD(0);
        } else {
            if (this._loaded) {
                return this._productsDD;
            }
        }
        return [{value: '', label: 'Loading....'}];
    }

    loadProductsDD(offset: number = 0, useSelectValue: string = "Y") {
        this._loading = true;
        let limit: number = 500;
        this._apiService.setActionUrl('products/products');
        this._apiService.setSort({field: 'name', direction: 'ASC'});
        this._apiService.setLimit({records: limit, offset: offset});
        this._apiService.getDropDown(JSON.stringify(['name', 'producttype->code']), 'id', useSelectValue, 150).subscribe(response => {
            if (response.success) {
                let curDD = this._productsDD;
                this._productsDD = curDD.concat(response.data.records);
                if (limit <= response.data.records.length) {
                    this.loadProductsDD(offset + limit, "N");
                } else {
                    this._loaded = true;
                    this._loading = false;
                }
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }


}
