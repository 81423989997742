export class ProductType {
    id: string;
    oldId: number;
    code: string;
    description: string;
    createdAt: number;
    modifiedAt: number;
    softDeleted: boolean;
    creatorId: string;
    modifierId: string;
}

export class Product {
    id: string = '';
    oldId: number;
    productTypeId: string;
    oldDatabase: string;
    productType: ProductType;
    supplierId: number;
    supplier: any;
    name: string;
    description: string;
    itemCode: string;
    materialCost: number;
    materialLoss: number;
    piecesPerHour: number;
    labourHours: number;
    createdAt: number;
    modifiedAt: number;
    softDeleted: boolean;
    creatorId: string;
    modifierId: string;
    creator: any;
    modifier: any;
}
