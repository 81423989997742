import {Component, OnInit} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ApiService, responseModel} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {Finish, ShippingMethod, Warranty} from "./masterdata.models";
import {MenuItem, SelectItem} from "primeng/primeng";
import {Setting} from "../base/base.model";

@Component({
    selector: 'app-masterdata',
    templateUrl: './masterdata.component.html',
    styleUrls: ['./masterdata.component.css']
})
export class MasterDataComponent implements OnInit {

    public finishesLoading: boolean = false;
    public warrantiesLoading: boolean = false;
    public shippingMethodsLoading: boolean = false;
    public settingsLoading: boolean = false;
    public selectedItem: string;
    public finishes: Finish[] = [];
    public warranties: Warranty[] = [];
    public shippingMethods: ShippingMethod[] = [];
    public settings: Setting[] = [];
    public selectedFinish: Finish;
    public selectedWarranty: Warranty;
    public selectedShippingMethod: ShippingMethod;
    public selectedSetting: Setting;
    public cm: MenuItem[] = [];

    private _showFinishes: boolean = false;
    private _showWarranties: boolean = false;
    private _showShippingMethods: boolean = false;
    private _showSettings: boolean = false;
    private _tmpFinishes: Finish[] = [];
    private _tmpWarranties: Warranty[] = [];
    private _tmpShippingMethods: ShippingMethod[] = [];
    private _tmpSettings: Setting[] = [];
    private _options: SelectItem[] = [];
    private _selectedOption: string;

    constructor(private _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService) {

    }

    ngOnInit(): void {
        this.loadFinishes();
        this.loadWarranties();
        this.loadShippingMethods();
        this.loadSettings();
        this._showFinishes = true;
        this._transService.get(['masterdata.finishes']).subscribe(
            response => {
                this.selectedItem = response['masterdata.finishes'];
            }
        );

        this._selectedOption = 'FIN';

        this._transService.get(['masterdata.finishes', 'masterdata.warranties', 'masterdata.shippingmethods', 'base.settings']).subscribe(
            response => {
                this._options.push({label: response['masterdata.finishes'], value: 'FIN'});
                this._options.push({label: response['masterdata.warranties'], value: 'WAR'});
                this._options.push({label: response['masterdata.shippingmethods'], value: 'SHM'});
                this._options.push({label: response['base.settings'], value: 'SET'});
            }
        );

        this._transService.get(['base.edit', 'base.delete', 'base.details']).subscribe(
            response => {
                if (this._auth.modules['masterdata'].authLevel >= 20) {
                    //this.cm.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editRecord()});
                }
                if (this._auth.modules['masterdata'].authLevel >= 40) {
                    this.cm.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteRecord()});
                }
            }
        );
    }

    editRecord() {

    }

    deleteRecord() {
        let recordId: string = '';
        if (this._showFinishes) {
            this._apiService.setActionUrl('masterdata/finishes');
            recordId = this.selectedFinish.id;
        }
        if (this._showWarranties) {
            this._apiService.setActionUrl('masterdata/warranties');
            recordId = this.selectedWarranty.id;
        }
        if (this._showShippingMethods) {
            this._apiService.setActionUrl('masterdata/shippingmethods');
            recordId = this.selectedShippingMethod.id;
        }
        this._apiService.deleteRecord(recordId).subscribe(response => {
            if (response.success) {
                this.loadData();
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    selectItem(event) {
        this._showFinishes = false;
        this._showWarranties = false;
        this._showShippingMethods = false;
        this._showSettings = false;
        this._selectedOption = event.value.value;
        switch (this._selectedOption) {
            case 'FIN':
                this.selectedItem = this._transService.instant('masterdata.finishes');
                this._showFinishes = true;
                break;
            case 'WAR':
                this.selectedItem = this._transService.instant('masterdata.warranties');
                this._showWarranties = true;
                break;
            case 'SHM':
                this.selectedItem = this._transService.instant('masterdata.shippingmethods');
                this._showShippingMethods = true;
                break;
            case 'SET':
                this.selectedItem = this._transService.instant('base.settings');
                this._showSettings = true;
        }
        this.loadItems();
    }

    loadItems() {
        switch (this._selectedOption) {
            case 'FIN':
                this.loadFinishes();
                break;
            case 'WAR':
                this.loadWarranties();
                break;
            case 'SHM':
                this.loadShippingMethods();
                break;
            case 'SET':
                this.loadSettings();
                break;
        }
    }

    createItem() {
        switch (this._selectedOption) {
            case 'FIN':
                let finish = new Finish();
                finish.id = '';
                this.finishes.unshift(finish);
                break;
            case 'WAR':
                let war = new Warranty();
                war.id = '';
                this.warranties.unshift(war);
                break;
            case 'SHM':
                let shm = new ShippingMethod();
                shm.id = '';
                this.shippingMethods.unshift(shm);
                break;
            case 'SET':
                let set = new Setting();
                set.id = '';
                this.settings.unshift(set);
        }
    }

    loadFinishes() {
        this.finishesLoading = true;
        this._apiService.setActionUrl('masterdata/finishes');
        this._apiService.getStore().subscribe(response => {
            this.finishesLoading = false;
            if (response.success) {
                this.finishes = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadWarranties() {
        this.warrantiesLoading = true;
        this._apiService.setActionUrl('masterdata/warranties');
        this._apiService.getStore().subscribe(response => {
            this.warrantiesLoading = false;
            if (response.success) {
                this.warranties = response.data.records
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadShippingMethods() {
        this.shippingMethodsLoading = true;
        this._apiService.setActionUrl('masterdata/shippingmethods');
        this._apiService.getStore().subscribe(response => {
            this.shippingMethodsLoading = false;
            if (response.success) {
                this.shippingMethods = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    loadSettings() {
        this.settingsLoading = true;
        this._apiService.setActionUrl('base/settings');
        this._apiService.getStore().subscribe(response => {
            this.settingsLoading = false;
            if (response.success) {
                this.settings = response.data.records;
            } else {
                this._auth.addError(response.errorMsg);
            }
        })
    }

    loadData() {
        if (this._showFinishes) {
            this.loadFinishes();
        }
        if (this._showWarranties) {
            this.loadWarranties();
        }
        if (this._showShippingMethods) {
            this.loadShippingMethods();
        }
        if (this._showSettings) {
            this.loadSettings();
        }
    }

    finishEditInit(finish: Finish) {
        this._tmpFinishes[finish.id] = { ... finish };
    }

    finishEditSave(finish: Finish) {
        if (finish.code.length > 0) {
            this._apiService.setActionUrl('masterdata/finishes');
            if (finish.id.length === 0) {
                this._apiService.createRecord(JSON.stringify(finish)).subscribe(response => {
                    if (response.success) {
                        this.selectedFinish = new Finish();
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                });
            } else {
                this._apiService.updateRecord(JSON.stringify(finish)).subscribe(response => {
                    if (response.success) {
                        this.selectedFinish = new Finish();
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                });
            }
        }
    }

    finishEditCancel(finish: Finish, row: number) {
        this.finishes[row] = this._tmpFinishes[finish.id];
        this._tmpFinishes[finish.id] = null;
    }

    warrantyEditInit(warranty: Warranty) {
        this._tmpWarranties[warranty.id] = { ... warranty };
    }

    warrantyEditSave(warranty: Warranty) {
        if (warranty.description.length > 0) {
            this._apiService.setActionUrl('masterdata/warranties');
            if (warranty.id.length === 0) {
                this._apiService.createRecord(JSON.stringify(warranty)).subscribe(response => {
                    if (response.success) {
                        this.selectedWarranty = new Warranty();
                    }
                });
            } else {
                this._apiService.updateRecord(JSON.stringify(warranty)).subscribe(response => {
                    if (response.success) {
                        this.selectedWarranty = new Warranty();
                    }
                });
            }
        }
    }

    warrantyEditCancel(warranty: Warranty, row: number) {
        this.warranties[row] = this._tmpWarranties[warranty.id];
        this._tmpWarranties[warranty.id] = null;
    }

    shipEditInit(shipMethod: ShippingMethod) {
        this._tmpShippingMethods[shipMethod.id] = { ... shipMethod };
    }

    shipEditSave(shipMethod: ShippingMethod) {
        if (shipMethod.shippingMethod.length > 0) {
            this._apiService.setActionUrl('masterdata/shippingmethods');
            if (shipMethod.id.length === 0) {
                this._apiService.createRecord(JSON.stringify(shipMethod)).subscribe(response => {
                    if (response.success) {
                        this.selectedShippingMethod = new ShippingMethod();
                    }
                });
            } else {
                this._apiService.updateRecord(JSON.stringify(shipMethod)).subscribe(response => {
                    if (response.success) {
                        this.selectedShippingMethod = new ShippingMethod();
                    }
                });
            }
        }
    }

    shipEditCancel(shipMethod: ShippingMethod, row: number) {
        this.shippingMethods[row] = this._tmpShippingMethods[shipMethod.id];
        this._tmpShippingMethods[shipMethod.id] = null;
    }

    settingEditInit(setting: Setting) {
        this._tmpSettings[setting.id] = { ... setting };
    }

    settingEditSave(setting: Setting) {
        this._apiService.setActionUrl('base/settings');
        if (setting.id.length === 0) {
            this._apiService.createRecord(JSON.stringify(setting)).subscribe(response => {
                if (response.success) {
                    this.selectedSetting = new Setting();
                }
            });
        } else {
            this._apiService.updateRecord(JSON.stringify(setting)).subscribe(response => {
                if (response.success) {
                    this.selectedSetting = new Setting();
                }
            });
        }
    }

    settingEditCancel(setting: Setting, row: number) {
        this.shippingMethods[row] = this._tmpShippingMethods[setting.id];
        this._tmpShippingMethods[setting.id] = null;
    }

    uploadFile(event, setting: Setting) {
        event.formData.append('type', setting.type);
        event.formData.append('id', setting.id);
    }

    uploadReady(event) {
        console.info(event);
        let response:responseModel = JSON.parse(event.xhr.response);
        if (!response.success) {
            this._auth.addError(response.errorMsg);
        }
    }
}
