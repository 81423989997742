import {User} from "../users/users.models";
import {Finish, ShippingMethod, Warranty} from "../masterdata/masterdata.models";
import {Product} from "../products/products.model";

export class Order {
    id: string = '';
    oldId: number;
    oldDatabase: string;
    orderNumber: number;
    versionNumber: number;
    parentId: string;
    employeeId: string;
    employee: User;
    orderCustomers: OrderCustomer[] = [];
    orderCustomerId: string;
    contactId: number;
    // contact: any;
    customerId: number;
    customer: any;
    warrantyId: string;
    warranty: Warranty;
    shippingMethodId: string;
    shippingMethod: ShippingMethod;
    projectTitle: string;
    projectDescription: string;
    remarks: string;
    orderDate: number;
    orderDateDate: Date;
    sendDate: number;
    sendDateDate: Date;
    labourHours: number;
    labourCost: number;
    travelHours: number;
    travelCost: number;
    craneHours: number;
    craneCost: number;
    transportHours: number;
    safetyHours: number;
    calculationHours: number;
    calculationCost: number;
    preparationHours: number;
    inspectionHours: number;
    parkingCost: number;
    profitAndRisk: number;
    discount: number;
    bonusContractPrice: number;
    totalPrice: number;
    vatLabour: number;
    vatMaterials: number;
    standardText01: string;
    standardText02: string;
    standardText03: string;
    standardText04: string;
    standardText05: string;
    standardConditions: string;
    standardInvoiceDescription: string;
    order: boolean;
    addQuotation: boolean;
    windLoad: boolean;
    constructionPhysicalCalculation: boolean;
    cadDrawings: boolean;
    photos: boolean;
    privateAssignment: boolean;
    goProjectId: number;
    year: number;
    newTotal: number;
    readOnly: boolean;
    creatorId: string;
    creator: User;
    createdAt: number;
    softDeleted: boolean;
    modifierId: string;
    modifier: User;
    modifiedAt: number;
    displayName: string;
    workAddress: string;
    workCity: string;
    displayTitle: string;
}

export class OrderCustomer {
    id: string = '';
    orderId: string;
    contactId: number;
    contact: any;
    customerId: number;
    customer: any;
    sortOrder: string;
    softDeleted: boolean;
}

export class OrderPart {
    id: string = '';
    oldId: number;
    oldDatabase: string;
    orderId: string;
    code: string = 'A';
    description: string;
    size: number;
    _sizeFilled: boolean;
    length: number;
    _lengthFilled: boolean;
    salesPrice: number;
    creatorId: string;
    creator: User;
    createdAt: number;
    softDeleted: boolean;
    modifierId: string;
    modifier: User;
    modifiedAt: number;
    finishes: OrderPartsFinish[];
}

export class OrderPartsFinish {
    id: string = '';
    oldId: number;
    oldDatabase: string;
    orderPartId: string;
    finishId: string = '';
    finish: Finish;
    description: string;
    size: number;
    sortOrderNumber: number;
    creatorId: string;
    creator: User;
    createdAt: number;
    softDeleted: boolean;
    modifierId: string;
    modifier: User;
    modifiedAt: number;
    details: OrderDetail[];
}

export class OrderDetail {
    id: string = '';
    oldId: number;
    oldDatabase: string;
    orderPartFinishId: string = '';
    productId: string = '';
    product: Product;
    materialCost: number;
    materialFactor: number;
    materialLoss: number;
    labourCost: number;
    labourFactor: number;
    quantity: number;
    explanation: string;
    sortOrderNumber: number;
    creatorId: string;
    creator: User;
    createdAt: number;
    softDeleted: boolean;
    modifierId: string;
    modifier: User;
    modifiedAt: number;
}
