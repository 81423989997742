import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {ProductsComponent} from "./products.component";

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        ProductsComponent
    ],
    exports: [

    ]
})
export class ProductsModule {}