import {Component, OnInit} from "@angular/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {ModuleModel as Module} from "./modules.models";
import {ApiService} from "../api.service";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem} from "primeng/api";

@Component({
    selector: 'app-modules',
    templateUrl: './modules.component.html',
    styleUrls: ['./modules.component.css']
})
export class ModulesComponent implements OnInit {

    public modules: Module[];
    public selectedModule: Module;
    public newModule: Module;
    public newMod: boolean = false;
    public displayDialog: boolean = false;
    public showTable: boolean = true;
    public minHeight: number = 700;
    public minWidth: number = 1000;
    public loading: boolean;
    private _contextMenu: MenuItem[] = [];

    constructor(private _auth: AuthService, private _st: SupportTools, private _apiService: ApiService, private _aclService: AclService,
                private _transService: TranslateService) {

    }

    ngOnInit() {
        this.loadModules();
        this._transService.get(['base.edit', 'base.delete']).subscribe(
            response => {
                if (this._auth.modules['groups'].authLevel >= 20) {
                    this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editModule()});
                }
            }
        )
    }

    loadModules() {
        this.loading = true;
        this._apiService.setActionUrl('modules/modules');
        this._apiService.getStore()
            .subscribe(response => {
                this.loading = false;
                if (response.success) {
                    this.modules = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            }, error => {
                this._auth.addError(error.error.text);
            });
    }

    editModule() {
        this._aclService.getAcl(this.selectedModule.aclItemId);
        this.displayDialog = true;
        this.showTable = false;
    }

    installModule(newModule: Module) {
        // Display detailsscreen
        this.displayDialog = true;
        this.showTable = false;
        this.newModule = newModule;
        if (this.newModule.id === 'nnb') {
            this._apiService.setActionUrl('modules/modules');
            this._apiService.specialPostAction(JSON.stringify(newModule), 'install')
                .subscribe((resp) => {
                    if (resp.success) {
                        this.newModule = resp.data.records[0];
                        this._aclService.getAcl(this.newModule.aclItemId);
                    } else {
                        this._auth.addError(resp.errorMsg);
                    }
                }, error => {
                    this._auth.addError(error.error.text);
                });
        } else {
            this._aclService.getAcl(this.newModule.aclItemId);
        }
    }

    uninstallModule(oldModule: Module) {
        this._apiService.setActionUrl('modules/modules');
        this._apiService.deleteRecord(oldModule.id)
            .subscribe((resp) => {
                if (resp.success) {
                    this.loadModules();
                    this.cancel();
                } else {
                    this._auth.addError(resp.errorMsg);
                }
            }, error => {
                this._auth.addError(error.error.text);
            });
    }

    save() {
        this._apiService.setActionUrl('modules/modules');
        this._apiService.createRecord(JSON.stringify(this.newModule))
            .subscribe((resp) => {
                if (resp.success) {
                    this.loadModules();
                    this.cancel();
                } else {
                    this._auth.addError(resp.errorMsg);
                    this.cancel();
                }
            }, error => {
                this._auth.addError(error.error.text);
            });
    }

    cancel() {
        this.displayDialog = false;
        this.showTable = true;
        this.selectedModule = null;
    }

    onRowDblclick(event, module) {
        if (this._auth.modules['groups'].authLevel >= 20) {
            this.selectedModule = module;
            this.editModule();
        }
    }

}